import { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import Cookies from "js-cookie"
import {
  Button,
  Container,
  Card,
  CardBody,
  CardTitle,
  Col,
  CardImg,
  Spinner,
  Row,
} from "reactstrap"
import img2 from "./img-2.jpg"
import { ins } from "./ins"

const Needy = () => {
  const [needdata, setNeeddata] = useState([
    {
      age: 0,
      country: "",
      id: 0,
      name: "",
      orphanage_name: "",
      photo: "",
    },
  ])
  const [needvisible, setNeedvisible] = useState(false)

  const request = () => {
    ins({
      method: "get",
      url: "Orphan/Orphans",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then(res => {
        setNeeddata(res.data)
        setNeedvisible(true)
      })
      .catch(() => {})
  }

  const road = e => {
    window.location.href = "/needinformation/" + e.target.id
    //e.target.id
  }

  useEffect(() => {
    request()
  }, [])

  return (
    <div className="page-content">
      <MetaTags>
        <title>Yetim Takip</title>
      </MetaTags>
      <div></div>
      <Container fluid>
        {needvisible ? (
          <Row>
            {needdata?.length > 0
              ? needdata.map((i, index) => (
                  <Col xl={4} key={index}>
                    <Card>
                      <div className="d-flex" style={{ height: "260px" }}>
                        <CardImg
                          top
                          style={{ height: "auto", maxHeight: "260px" }}
                          className="img-fluid m-auto w-auto"
                          src={
                            i.photo
                              ? "https://dernekapi.yetimsevindir.com/images/" +
                                i.photo
                              : img2
                          }
                          alt=""
                        />
                      </div>
                      <CardBody>
                        <CardTitle className="mt-2 px-1">
                          {i.name}({i.age})
                        </CardTitle>
                        <hr />
                        <ul className="list-group list-group-flush px-1">
                          <li className="d-flex">
                            <strong className="w-50">Yaşadığı Yer </strong>
                            <p> {i.country}</p>
                          </li>
                          <li className="d-flex">
                            <strong className="w-50"> Yetimhanesi </strong>
                            <p>
                              {i.orphanage_name
                                ? i.orphanage_name
                                : "Yetimhane Kaydı Yok"}
                            </p>
                          </li>
                        </ul>
                        <CardBody className="row">
                          <Button
                            id={i.id}
                            color="success"
                            outline
                            onClick={road}
                          >
                            Bilgilerini Görüntüle
                          </Button>
                        </CardBody>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              : "Yetim bulunamadı..."}
          </Row>
        ) : (
          <div className="d-flex justify-content-center w-100 mb-2">
            <Spinner className="ms-4" color="secondary" />
          </div>
        )}
      </Container>
    </div>
  )
}
export default Needy
