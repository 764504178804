import { Link } from "react-router-dom"

const SatisSozlesmesi = () => {
  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="card">
          <div className="card-body">
            <div>
              <h1 className="fs-4 text-dark pb-md-2 mb-3 mb-md-4">
                Yetimsevindir Mesafeli Satış Sözleşmesi
              </h1>
              <div className="text-dark fs-lg pb-md-2">
                <p>
                  "SATICI/SAĞLAYICI" sıfatıyla 6502 sayılı Tüketicinin Korunması
                  Hakkında Kanun, Mesafeli Sözleşmelere Dair Yönetmelik ve
                  ilgili mevzuata uygun olarak hazırladığımız işbu MESAFELİ
                  SATIŞ SÖZLEŞMESİ'ni(Bundan böyle kısaca <b>"Sözleşme"</b>{" "}
                  olarak anılacaktır.) siz değerli ziyaretçimizin onayına
                  sunuyoruz. Lütfen Sözleşme'nin tüm hükümlerini okuduğunuzdan
                  ve anladığınızdan emin olduktan sonra Sözleşme'yi onaylayınız.
                </p>
                <p>
                  <b>
                    Yetimsevindir'e(<Link to="/">yetimsevindir.com</Link>)
                    erişim sağlayabildiğiniz her cihazda Sözleşme'yi elektronik
                    olarak onaylamanız, tüm koşulları kabul etmiş olmanız
                    sonucunu doğurur.
                  </b>
                </p>
              </div>

              <div className="pb-md-2">
                <h3 className="h5 text-dark">MADDE 1 - TARAFLAR</h3>
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="fs-lg text-dark">
                          <b>Ticari Unvan</b>
                        </td>
                        <td className="text-dark">Yetimsevindir</td>
                      </tr>
                      <tr>
                        <td className="fs-lg text-dark">
                          <b>Adres</b>
                        </td>
                        <td className="text-dark">...</td>
                      </tr>
                      <tr>
                        <td className="fs-lg text-dark">
                          <b>Vergi No</b>
                        </td>
                        <td className="text-dark">...</td>
                      </tr>
                      <tr>
                        <td className="fs-lg text-dark">
                          <b>MERSİS No</b>
                        </td>
                        <td className="text-dark">...</td>
                      </tr>
                      <tr>
                        <td className="fs-lg text-dark">
                          <b>E-Posta</b>
                        </td>
                        <td className="text-dark">
                          <a href="mailto:...">...</a>
                        </td>
                      </tr>
                      <tr>
                        <td className="fs-lg text-dark">
                          <b>Telefon</b>
                        </td>
                        <td className="text-dark">
                          <a href="tel:...">(...)</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h4 className="h6">1.2. BAĞIŞÇI</h4>

                <p className="text-dark">
                  (Ad-Soyad) <br />
                  (T.C.K.N.) <br />
                  (Adres) <br />
                  (E-Posta Adresi) <br />
                  (Fatura Bilgileri) <br />
                </p>
              </div>

              <div className="pb-md-2">
                <h3 className="h5 text-dark">MADDE 2 - KONU</h3>
                <p className="text-dark">
                  İşbu sözleşme, BAĞIŞÇI tarafından bağış yapılan ürün veya
                  hizmetlerin alım-satımı ile ilgili taraflar arasındaki hak ve
                  yükümlülüklerin belirlenmesi amacıyla düzenlenmiştir. BAĞIŞÇI,
                  bağışını karşılık beklemeden ve tamamen isteğe bağlı olarak
                  yapmaktadır.
                </p>
              </div>

              <div className="pb-md-2">
                <h3 className="h5 text-dark">MADDE 3 - HİZMET</h3>
                <div>
                  <p className="text-dark">
                    BAĞIŞÇI, internet üzerinden veya başka bir uzaktan iletişim
                    aracıyla bağış yapmakta ve bağışını Yetimsevindir
                    aracılığıyla gerçekleştirmektedir. Bağış, BAĞIŞÇI'nın tek
                    taraflı rızası ile yapılır ve bağışın yapıldığı tarih
                    itibarıyla geçerli olur.
                  </p>
                </div>
              </div>

              <div className="pb-md-2">
                <h3 className="h5 text-dark">MADDE 4 - ÖDEME</h3>
                <div className="text-dark">
                  Sözleşme konusu bağış tutarı; Yetimsevindir tarafından bilgisi
                  verilen kredi / banka kartından ödeme kuruluşu "... Ödeme
                  Hizmetleri A.Ş." aracılığıyla gerçekleştirilir.
                </div>
              </div>

              <div className="pb-md-2">
                <h3 className="h5 text-dark">
                  MADDE 5 - TARAFLARIN HAK VE YÜKÜMLÜLÜKLERİ
                </h3>
                <div className="text-dark">
                  <p>
                    <b>5.1.</b> Yetimsevindir bağışın doğru bir şekilde
                    alınmasını sağlamakla yükümlüdür.
                  </p>
                  <p>
                    <b>5.2.</b> BAĞIŞÇI, bağış sürecinin tamamlanmasından önce
                    verilen bilgilerin doğru olduğunu beyan eder.
                  </p>
                </div>
              </div>

              <div className="pb-md-2">
                <h3 className="h5 text-dark">
                  MADDE 6 - CAYMA HAKKI, İPTAL, DEĞİŞİKLİK ve İADE
                </h3>
                <div className="text-dark">
                  <p>
                    <b>6.1.</b> Bağış, tamamen BAĞIŞÇI tarafından gönüllü olarak
                    yapılan bir işlem olduğundan, bağışın iadesi veya iptali
                    mümkün değildir. Ancak, teknik bir hata veya yanlış işlem
                    sonucu yapılan bağışın iptali için BAĞIŞÇI, bağışı yaptıktan
                    sonra en geç 14(ondört) iş günü içinde kurum ile iletişime
                    geçebilir.
                  </p>
                  <p>
                    Yukarıda belirtilen durumun gerçekleşmesi halinde,
                    BAĞIŞÇI'nın ödemiş olduğu bağış tutarı, Yetimsevindir'in
                    ödeme işlemini gerçekleştirdiği ilgili banka hesabına en geç
                    14(ondört) iş günü içinde iade edilir.
                  </p>
                </div>
              </div>
              <div className="pb-md-2">
                <h3 className="h5 text-dark">
                  MADDE 7 - ÖN BİLGİLENDİRMEYE İLİŞKİN TEYİT
                </h3>
                <div className="text-dark">
                  <p>
                    BAĞIŞÇI işbu Sözleşme'yi onayladığında Mesafeli Sözleşmeler
                    Yönetmeliğinin 6.(altıncı) maddesinde belirtilen usullere
                    uygun olarak SATICI/SAĞLAYICI tarafından kendisine ön
                    bilgilendirmede bulunulduğunu kabul etmiş sayılır.
                  </p>
                </div>
              </div>
              <div className="pb-md-2">
                <h3 className="h5 text-dark">
                  MADDE 8 - BİLGİ GÜVENLİĞİ ve KİŞİSEL VERİLER
                </h3>
                <div className="text-dark">
                  <p>
                    BAĞIŞÇI tarafından Yetimsevindir'e(yetimsevindir.com)
                    girilen bilgilerin ve işlemlerin güvenliği için gerekli
                    önlemler, SATICI/SAĞLAYICI tarafındaki sistem altyapısında,
                    bilgi ve işlemin mahiyetine göre teknik imkanları ölçüsünde
                    alınmıştır. Ancak söz konusu bilgiler BAĞIŞÇI tarafından ve
                    BAĞIŞÇI'ya ait cihaz üzerinden girildiğinden, bu bilgilerin
                    korunması ve bu bilgilere ilgisiz kişilerce erişilememesi
                    için, virüs ve benzeri zararları uygulamalara ilişkin
                    olanlar dahil, gerekli tedbirlerin alınması sorumluluğu
                    BAĞIŞÇI'ya aittir.
                  </p>
                </div>
              </div>

              <div className="pb-md-2">
                <h3 className="h5 text-dark">
                  MADDE 9 - FİKRİ MÜLKİYET HAKLARI
                </h3>
                <div className="text-dark">
                  <p>
                    BAĞIŞÇI'nın Yetimsevindir'de(
                    <Link to="/">yetimsevindir.com</Link>) yer alan hiçbir
                    görsele, görsel-işitsel içeriğe, materyale dair herhangi bir
                    lisans hakkı mevcut değildir ve bu Sözleşme'nin akdedilmesi
                    BAĞIŞÇI'ya söz konusu içerikler üzerinde lisans hakkı
                    sağlamaz. BAĞIŞÇI'nın Yetimsevindir üzerinden erişim
                    sağlayacağı tüm görsel/işitsel içeriklerin tüm telif hakları
                    SATICI/SAĞLAYICI'ya ve/veya içeriğin üretilmesine katkı
                    sunan iş ortaklarına aittir. BAĞIŞÇI'nın erişiminde bulunan
                    hiçbir içeriği işleme, çoğaltma, yayma, temsil, umuma
                    iletim, kiralama ve/veya satma hakkı yoktur.
                  </p>
                </div>
              </div>

              <div className="pb-md-2">
                <h3 className="h5 text-dark">
                  MADDE 10 - SORUMLULUĞUN SINIRLANDIRILMASI
                </h3>
                <div className="text-dark">
                  <p>
                    <b>10.1</b> BAĞIŞÇI, Yetimsevindir'de sunulan bilgi ve/veya
                    hizmetlerde eksiklik, iletişim sorunları, teknik problemler,
                    altyapı ve/veya internet arızaları, elektrik kesintisi veya
                    başkaca problemler olabileceğini kabul etmekte olup, bu
                    türde problemler oluşması durumunda SATICI/SAĞLAYICI gerek
                    bağış başlamadan önce, gerek bağış esnasında veya bağış
                    yapılmış olsa dahi BAĞIŞÇI'ya herhangi bir bildirimde
                    bulunmaya gerek olmaksızın ve/veya sebep göstermeksizin
                    bağışı durdurmaya ve/veya sona erdirmeye ve/veya iptal
                    etmeye yetkilidir. BAĞIŞÇI bu nedenlerle
                    SATICI/SAĞLAYICI'dan hiçbir nam altında hak veya alacak
                    talep edemez.
                  </p>

                  <p>
                    <b>10.2</b> SATICI/SAĞLAYICI'nın, ağır kusur veya kast
                    halleri hariç olmak üzere, BAĞIŞÇI'nın doğrudan ve/veya
                    dolaylı zararlarından sorumluluğu bulunmamaktadır.
                  </p>
                </div>
              </div>

              <div className="pb-md-2">
                <h3 className="h5 text-dark">
                  MADDE 11 - KREDİ KARTI / BANKA KARTI BİLGİLERİNİN SAKLANMASI
                </h3>
                <div className="text-dark">
                  <p>
                    BAĞIŞÇI'nın kredi/banka kartı bilgileri SATICI/SAĞLAYICI
                    tarafından saklanmamaktadır. BAĞIŞÇI'nın kredi/banka kartı
                    bilgileri Türkiye Cumhuriyet Merkez Bankası tarafından
                    yetkilendirilen aracı ödeme kuruluşu olan ve güvenli ödeme
                    sağlayan ... tarafından saklanacaktır.
                  </p>
                </div>
              </div>

              <div className="pb-md-2">
                <h3 className="h5 text-dark">MADDE 12 - SÖZLEŞMENİN FESHİ</h3>
                <div className="text-dark">
                  <p>
                    <b>14.1</b> SATICI/SAĞLAYICI, taahhüt ettiği süre içinde
                    edimini yerine getirmek zorundadır. SATICI/SAĞLAYICI'nın bu
                    yükümlülüğünü yerine getirmemesi durumunda, BAĞIŞÇI
                    sözleşmeyi feshedebilir. Sözleşmenin feshi durumunda,
                    SATICI/SAĞLAYICI, tahsil edilen tüm ödemeleri fesih
                    bildiriminin kendisine ulaştığı tarihten itibaren on dört
                    gün içinde BAĞIŞÇI'ya iade etmek zorundadır.
                  </p>
                </div>
              </div>

              <div className="pb-md-2">
                <h3 className="h5 text-dark">
                  MADDE 13 - UYUŞMAZLIKLARIN ÇÖZÜMÜ
                </h3>
                <div className="text-dark">
                  <p>
                    Bu Sözleşme'den doğabilecek uyuşmazlıklarda, Ticaret
                    Bakanlığı'nca yasa gereği her yıl belirlenen/ilan edilen
                    parasal sınırlar dahilinde İl ve İlçe Tüketici Hakem
                    Heyetleri, bu sınırları aşan durumlarda Tüketici Mahkemeleri
                    yetkili ve görevlidir. BAĞIŞÇI, bu çerçevede, kendisinin
                    veya dilerse SATICI/SAĞLAYICI'nın yerleşim yerindeki Hakem
                    Heyetleri ve Tüketici Mahkemelerine başvurabilir.
                  </p>
                </div>
              </div>

              <div className="pb-md-2">
                <h3 className="h5 text-dark">MADDE 14 - DELİL SÖZLEŞMESİ</h3>
                <div className="text-dark">
                  <p>
                    İşbu Sözleşme'den doğabilecek tüm uyuşmazlıklarda
                    SATICI/SAĞLAYICI'ya ait ticari defter kayıtları, kalıcı veri
                    saklayıcıları, veri tabanları, sunucu ve bilgisayar
                    kayıtları ile tüm elektronik kayıtlar kesin delil teşkil
                    eder. BAĞIŞÇI, Sözleşme'yi onaylayarak işbu hükmün Hukuk
                    Muhakemeleri Kanunu'nun 193. maddesi uyarınca delil
                    sözleşmesi olduğunu kabul eder.
                  </p>
                </div>
              </div>

              <div className="pb-md-2">
                <h3 className="h5 text-dark">MADDE 15 - TEBLİGATLAR</h3>
                <div className="text-dark">
                  <p>
                    SATICI/SAĞLAYICI ile BAĞIŞÇI arasındaki her türlü bildirim
                    ve iletişim, mevzuatta sayılan zorunlu haller dışında, işbu
                    Sözleşme'de yazılı e-posta adresleri aracılığıyla
                    yapılacaktır.
                  </p>
                </div>
              </div>

              <div className="pb-md-2">
                <h3 className="h5 text-dark">MADDE 16 - İMZA VE YÜRÜRLÜK</h3>
                <div className="text-dark">
                  <p>
                    Toplam 16(on altı) maddeden ibaret bu Sözleşme; BAĞIŞÇI
                    tarafından elektronik ortamda onaylanmak suretiyle
                    kurulmuş/imzalanmış ve yürürlüğe girmiştir. Sözleşme'nin bir
                    örneği, SATICI/SAĞLAYICI tarafından BAĞIŞÇI'ya elektronik
                    posta aracılığıyla gönderilir. Ayrıca, Sözleşme'nin bir
                    örneği bağış işleminin gerçekleştiği tarihten itibaren 3 yıl
                    süre ile SATICI/SAĞLAYICI tarafından elektronik ortamda
                    muhafaza edilecektir.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SatisSozlesmesi
