import React from "react"
import { Redirect } from "react-router-dom"

//page
import Needy from "../pages/page/needy"
import Orphanage from "../pages/page/orphanage"
import Needinformation from "../pages/page/needinformation"
import Orphanageinformation from "../pages/page/orphanageinformation"
import Bagis from "pages/page/Bagis"
import SatisSozlesmesi from "pages/page/SatisSozlesmesi"
import Pages404 from "../pages/page/pages404"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Dashboard
import Donates from "pages/page/YapilanBagislar/Donates"

const userRoutes = [
  //page
  { path: "/needy", component: Needy },
  { path: "/orphanage", component: Orphanage },
  { path: "/needinformation/:id", component: Needinformation },
  { path: "/orphanageinformation/:id", component: Orphanageinformation },
  { path: "/donates", component: Donates },
  { path: "/donate", component: Bagis },
  { path: "/contract", component: SatisSozlesmesi },
  { path: "/pages404", component: Pages404 },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/needy" /> },
  { path: "", exact: false, component: () => <Redirect to="/needy" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
]

export { userRoutes, authRoutes }
