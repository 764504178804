import Yup from "./validate"

//! USER
export const PaymentSchema = Yup.object().shape({
  donation: Yup.string().required(),
  card_name: Yup.string().required(),
  card_number: Yup.string().min(16, "Geçersiz Kart Numarası").required(),
  card_date: Yup.string().min(5, "Geçersiz Tarih").required(),
  card_cvv: Yup.string().min(3, "Geçersiz CVV").required(),
  card_contract: Yup.boolean().oneOf([true], "Sözleşmeyi onaylayınız."),
})
