import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import qs from "query-string"
import axios from "axios"
import swal from "sweetalert"
import Cookies from "js-cookie"
import { ins } from "../page/ins"
import { Row, Col, CardBody, Card, Alert, Container, Button } from "reactstrap"
//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/logologin.svg"
import logo from "assets/images/logominidernek.svg"

//Import config
import { facebook, google } from "../../config"
import { setDefaultLocale } from "react-datepicker"
import ReactInputMask from "react-input-mask"

import PhoneInput from "react-phone-number-input"
import phoneInputTr from "react-phone-number-input/locale/tr.json"

const Login = props => {
  const [Visibbtn, setVisibbtn] = useState(false)
  const [username, setusername] = useState("")
  const [password, setpassword] = useState("")
  const [phone, setphone] = useState("")
  const [visiblephone, setvisiblephone] = useState(true)
  const [datareq, setdatareq] = useState("")
  const [loading, setLoading] = useState(false)

  // const { error } = useSelector(state => ({
  //   error: state.Login.error,
  // }))
  const handleValidPhone = (event, values) => {
    event.preventDefault()
    var data = qs.stringify({
      phone: phone,
    })
    var config = {
      method: "post",
      url: "Login/Login",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    }
    ins(config)
      .then(response => {
        setVisibbtn(true)
        setvisiblephone(false)
        setdatareq(response.data)
        // console.log(response.data)
        // Cookies.set("token", response.data.access_token)
        // Cookies.set("societyId", response.data.societyId)
        // Cookies.set("userId", response.data.userId)
        // Cookies.set("name", response.data.userName)
        // window.location.href = "/Needy"
      })
      .catch(() => {
        setVisibbtn(false)
        swal("Lütfen Bilgileri Kontrol Ediniz...", {
          title: "Yanlış Giriş Denemesi!",
          icon: "error",
          timer: 5000,
          button: {
            text: "Ok",
          },
        })
      })
  }

  const handleValidSubmit = (event, values) => {
    event.preventDefault()
    var data = qs.stringify({
      grant_type: "password",
      username: datareq,
      password: values.username,
    })
    var config = {
      method: "post",
      url: "GetToken",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    }

    ins(config)
      .then(response => {
        swal("Giriş Yaptınız.", {
          title: "",
          icon: "success",
          buttons: false,
          timer: 3000,
        })
        Cookies.set("token", response.data.access_token)
        Cookies.set("societyId", response.data.societyId)
        Cookies.set("userId", response.data.userId)
        Cookies.set("name", response.data.userName)
        window.location.href = "/Needy"
      })
      .catch(error => {
        swal("Lütfen Bilgileri Kontrol Ediniz...", {
          title: "Yalnış Giriş Denemesi!",
          icon: "error",
          timer: 5000,
          button: {
            text: "Ok",
          },
        })
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Bağışçı Giriş Ekranı</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row style={{ backgroundColor: "#fff" }}>
                    <Col xs={7}>
                      <div className="text-primary p-4"></div>
                    </Col>
                    <Col
                      className="col-5 align-self-end w-75 mb-5"
                      style={{
                        top: "18%",
                        left: "37%",
                        transform: "translate(-32%, -7%)",
                      }}
                    >
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="col-12 d-flex justify-content-center">
                      <h4>Bağışçı Giriş Ekranı</h4>
                    </div>
                    {/* <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        </span>
                      </div>
                    </Link> */}
                  </div>
                  {visiblephone ? (
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                          handleValidPhone(e, v)
                        }}
                      >
                        {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                        <div className="mb-3">
                          {/* <AvField
                            name="phone"
                            label="Telefon Numarası"
                            value={phone}
                            maxLength={11}
                            className="form-control"
                            placeholder="05999999999"
                            type="text"
                            required
                          /> */}
                          <PhoneInput
                            defaultCountry="TR"
                            placeholder="Telefon numaranızı giriniz."
                            name="Phone"
                            labels={phoneInputTr}
                            value={phone}
                            onChange={setphone}
                          />
                          {/* <ReactInputMask
                            placeholder="Telefon numaranızı giriniz."
                            required
                            className="form-control"
                            mask="(999)-999-9999"
                            onChange={e => {
                              setphone(
                                e.target.value.toString().replace(/\D/g, "")
                              )
                            }}
                            name="Phone"
                            value={phone}
                          /> */}
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            onClick={e => {
                              setVisibbtn(true)
                            }}
                          >
                            {Visibbtn ? (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            ) : (
                              "Kod Gönder"
                            )}
                          </button>
                        </div>
                      </AvForm>
                    </div>
                  ) : (
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={(e, v) => {
                          handleValidSubmit(e, v)
                        }}
                      >
                        {/* {error ? <Alert color="danger">{error}</Alert> : null} */}
                        <p>Lütfen Telefonunuza gelen doğrulama kodunu girin.</p>
                        <p>
                          Referans No:
                          <strong className="mx-3">{datareq}</strong>
                        </p>
                        <div className="mb-3">
                          <AvField
                            autoComplete="off"
                            name="username"
                            label="Sms Kodu"
                            value={username}
                            className="form-control"
                            placeholder=""
                            type="text"
                            required
                          />
                        </div>

                        {/* <div className="mb-3">
                        <AvField
                          name="password"
                          label="Şifre"
                          value={password}
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div> */}

                        {/* <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div> */}

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Giriş Yap
                          </button>
                        </div>

                        {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              )}
                            />
                          </li>
                          {/*<li className="list-inline-item">*/}
                        {/*  <TwitterLogin*/}
                        {/*    loginUrl={*/}
                        {/*      "http://localhost:4000/api/v1/auth/twitter"*/}
                        {/*    }*/}
                        {/*    onSuccess={this.twitterResponse}*/}
                        {/*    onFailure={this.onFailure}*/}
                        {/*    requestTokenUrl={*/}
                        {/*      "http://localhost:4000/api/v1/auth/twitter/revers"*/}
                        {/*    }*/}
                        {/*    showIcon={false}*/}
                        {/*    tag={"div"}*/}
                        {/*  >*/}
                        {/*    <a*/}
                        {/*      href=""*/}
                        {/*      className="social-list-item bg-info text-white border-info"*/}
                        {/*    >*/}
                        {/*      <i className="mdi mdi-twitter"/>*/}
                        {/*    </a>*/}
                        {/*  </TwitterLogin>*/}
                        {/*</li>*/}
                        {/*
                          <li className="list-inline-item">
                            <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => {}}
                            />
                          </li>
                        </ul>
                      </div> */}

                        {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div> */}
                      </AvForm>
                    </div>
                  )}
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p> */}
                <p>© {new Date().getFullYear()} Deva Yazılım.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}