import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Button,
  Label,
  FormGroup,
  Input,
  CardTitle,
} from "reactstrap"
import ReactInputMask from "react-input-mask"
import { Controller, useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { yupResolver } from "@hookform/resolvers/yup"
import { PaymentSchema } from "validation"
import { Link } from "react-router-dom"
import { NumericFormat } from "react-number-format"

const Bagis = () => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(PaymentSchema) })

  const submit = values => {
    console.log(values)
  }

  console.log(watch(), errors)

  return (
    <div className="page-content">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card>
              <CardBody>
                <CardTitle className="text-center fs-4">BAĞIŞ YAP</CardTitle>
                <form onSubmit={handleSubmit(submit)}>
                  <h5 className="mb3">Bağış Bilgileri</h5>
                  <FormGroup className="mb-3">
                    <Label>Bağış Tutarı</Label>
                    <Controller
                      name="donation"
                      defaultValue=""
                      control={control}
                      render={({ field: { onChange, ...field } }) => (
                        <NumericFormat
                          placeholder="Bağış Tutarı"
                          className="form-control"
                          allowNegative={false}
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="₺"
                          {...field}
                          onValueChange={e => {
                            onChange(e.value)
                          }}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="donation"
                      render={({ message }) => (
                        <div className="d-block invalid-feedback">
                          {message}
                        </div>
                      )}
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label>Açıklama</Label>
                    <Controller
                      name="description"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="Açıklama" {...field} />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="description"
                      render={({ message }) => (
                        <div className="d-block invalid-feedback">
                          {message}
                        </div>
                      )}
                    />
                  </FormGroup>
                  <hr />
                  <h5 className="mb-3">Kart Bilgileri</h5>
                  <FormGroup className="mb-3">
                    <Label>Kart Üzerindeki İsim</Label>
                    <Controller
                      name="card_name"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <Input placeholder="İsim Soyisim" {...field} />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="card_name"
                      render={({ message }) => (
                        <div className="d-block invalid-feedback">
                          {message}
                        </div>
                      )}
                    />
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <Label>Kart Numarası</Label>
                    <Controller
                      name="card_number"
                      defaultValue=""
                      control={control}
                      render={({ field }) => (
                        <ReactInputMask
                          placeholder="Kart Numarası"
                          className="form-control"
                          mask="9999 9999 9999 9999"
                          {...field}
                          onChange={e => {
                            field.onChange(
                              e.target.value.replace(/[^0-9]/g, "")
                            )
                          }}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="card_number"
                      render={({ message }) => (
                        <div className="d-block invalid-feedback">
                          {message}
                        </div>
                      )}
                    />
                  </FormGroup>
                  <div className="d-flex gap-3">
                    <FormGroup className="mb-3 flex-fill">
                      <Label>Son Kullanım Tarihi</Label>
                      <Controller
                        name="card_date"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <ReactInputMask
                            placeholder="Son Kullanım Tarihi"
                            className="form-control"
                            mask="m9/99"
                            formatChars={{
                              m: "[0-1]",
                              9: "[0-9]",
                            }}
                            {...field}
                            onChange={e => {
                              let [month, year] = e.target.value
                                .replace(/[^0-9/]/g, "")
                                .split("/")

                              if (month > 12) {
                                month = "12"
                              } else if (month === "00") {
                                month = "01"
                              }

                              // if (year) {
                              //   let currentYear = new Date()
                              //     .getFullYear()
                              //     .toString()
                              //     .substring(2, 4)

                              //   if (year?.length == 2 && year < currentYear) {
                              //     year = currentYear
                              //   }
                              // }

                              field.onChange([month, year].join("/"))
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="card_date"
                        render={({ message }) => (
                          <div className="d-block invalid-feedback">
                            {message}
                          </div>
                        )}
                      />
                    </FormGroup>
                    <FormGroup className="mb-3 flex-fill">
                      <Label>CVV</Label>
                      <Controller
                        name="card_cvv"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                          <ReactInputMask
                            placeholder="CVV"
                            className="form-control"
                            mask="999"
                            {...field}
                            onChange={e => {
                              field.onChange(
                                e.target.value.replace(/[^0-9]/g, "")
                              )
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="card_cvv"
                        render={({ message }) => (
                          <div className="d-block invalid-feedback">
                            {message}
                          </div>
                        )}
                      />
                    </FormGroup>
                  </div>
                  <FormGroup className="mb-3" check>
                    <Controller
                      name="card_contract"
                      defaultValue={false}
                      control={control}
                      render={({ field }) => (
                        <>
                          <Input type="checkbox" {...field} />
                          <Label check>
                            <Link to="/contract" target="_blank">
                              Mesafeli Satış Sözleşmesi
                            </Link>
                            'ni onaylayınız.
                          </Label>
                        </>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="card_contract"
                      render={({ message }) => (
                        <div className="d-block invalid-feedback">
                          {message}
                        </div>
                      )}
                    />
                  </FormGroup>

                  <div className="mt-3">
                    <Button color="primary" type="submit" className="w-100">
                      Bağış Yap
                    </Button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Bagis
